
  import router from '@/router'
  import { useRoute } from 'vue-router';
  import Constains from '@/components/Constains';
  import { StopOutlined } from '@ant-design/icons-vue';
  import type { SelectProps } from 'ant-design-vue';
  import { defineComponent, reactive, onMounted,ref } from 'vue';
  import axios from 'axios';
  import { useCookies } from 'vue3-cookies';
  
  interface FormState {
    email: string;
    password: string;
    remember: boolean;
  }
  export default defineComponent({
    components: {
      StopOutlined,
    },
    setup() {
      const checked = ref<boolean>(false)
      const loading = ref<boolean>(false)
      const visible = ref<boolean>(false)
      const modalvisible = ref<boolean>(false)
      const cookies  = useCookies();
      const formState = reactive<FormState>({
        email: '',
        password: '',
        remember: true,
      });
      const options = ref<SelectProps['options']>([]);
      const handleChange = (value: string) => {
        console.log(`selected ${value}`);
      };
  
      onMounted(async () => {
        const route = useRoute()
        const staffcode = route.params.staffcode as string;
        console.log('staffcode',staffcode)
        var token = cookies.cookies.get('token');
        console.log("token:"+token)
        formState.email = staffcode

        if(staffcode !== undefined){
          cookies.cookies.set('login',staffcode);
          if( token !== null ){
            const res = await axios.get(Constains.tracerbaseURL+'/api/Login/'+token)
            if(res.status == 400){
              formState.email = staffcode
              return
            } else {
              const json = res.data;
              if(json.email == staffcode){
                //ページ遷移
                router.push('/scheduler');
              } else {
                //異なるアカウント
                modalvisible.value = true
              }
            }
          }
        } else {
          //router.push('/scheduler');
        }
      })
      const onFinish = async(values: any) => {
        console.log('Success:', values);
        var usercode = formState.email;
        var password = formState.password;
        const json = {
              'email':usercode,
              'pass':password,
            }
        visible.value = false;
        loading.value = true;

        try{
            const res = await axios.post(Constains.tracerbaseURL+'/api/Login',JSON.stringify(json),
              {headers:{'Content-Type': 'application/json', }});
            console.log('states:'+res.status);
            console.log('response:'+JSON.stringify(res.data));
  
            if(res.status == 200){
              const token = res.data['token'];
              if(formState.remember){
                //cookieに保存.有効期限無し
                //cookies.cookies.set('usercode',usercode,'-1');
                cookies.cookies.set('token',token ,'-1');
              } else {
                //cookieの保存期限をnullにすれば閉じれば消せる
                //cookies.cookies.set('usercode',usercode,);
                cookies.cookies.set('token',token ,);
              }
              cookies.cookies.set('logincheck',checked.value.toString())
              //cookies.cookies.set('login',staffcode);

              const isauth = await axios.get(Constains.tracerbaseURL+'/api/Login/'+token,{headers:{
                'Authorization' :'Bearer '+token, }});
                if(isauth.data.authority == 0){
                  cookies.cookies.set('login',formState.email); 
                }

              loading.value = false;

              router.push('/scheduler');
            } else {
              visible.value = true;
              loading.value = false;
            }
          } catch(e){
            console.log(e)

            //テスト用
            //cookies.cookies.set('token','token' ,'-1');
            //router.push('/scheduler');

            visible.value = true;
            loading.value = false;
          }
      };
  
      const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
      };

      const handleOk = () => {
        console.log("log out")
        cookies.cookies.remove('token')
        modalvisible.value = false;
      }

      const handleCancel = () => {
        router.push('/scheduler');
      }

      const CameraMode = () => {
        //const url = '/gokouScheduler/html/QRCamera.html'
        const url = '/html/QRCamera.html'
        window.location.href = url
      }

      return {
        cookies,
        formState,
        onMounted,
        onFinish,
        onFinishFailed,
        handleChange,
        options,
        checked,
        visible,
        handleOk,
        modalvisible,
        handleCancel,
        loading,
        CameraMode,
      };
    },
  });
  